import { CellStyleModel } from "@syncfusion/ej2-react-spreadsheet";

//pop-up dialog
export const firstPosition = { X: "center", Y: "center" };
export let settings: any = { effect: "Zoom", duration: 400, delay: 0 };

export const cellStyle: CellStyleModel = {
  fontFamily: "Calibri",
  fontSize: "9pt",
  textAlign: "right",
};

export const ColumnStyle: CellStyleModel = { 
  fontWeight: "normal",
  textAlign: "right",
  fontFamily: "Calibri",
  fontSize: "9pt"
};

export const RequiredColumnStyle: CellStyleModel =  {
  fontSize: "9pt",
  fontFamily: "Calibri",
  textAlign: "right",
  backgroundColor: "Gray",
};

export const AutomaticColumnStyle: CellStyleModel = {
  fontSize: "9pt",
  fontFamily: "Calibri",
  textAlign: "right",
  backgroundColor: "#f0f0f0",
  color: "#808080"
};
export const AdminColumnStyle: CellStyleModel = {
  fontSize: "9pt",
  fontFamily: "Calibri",
  textAlign: "right",
  backgroundColor: "#cae1f8"
};

export const ColumnHeaderStyle: CellStyleModel = {
  fontWeight: "bold",
  textAlign: "center",
  fontFamily: "Calibri",
  fontSize: "10pt",
  backgroundColor: "#D3D3D3"
};

export const CellStyle: CellStyleModel = {
  fontFamily: "Calibri",
  fontSize: "9pt",
  textAlign: "right",
};

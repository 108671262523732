import { classList, createElement } from "@syncfusion/ej2-base";
import {
  CellModel,
  getCellAddress,
  getColumnHeaderText,
  getSheetName,
  MenuSelectEventArgs,
  SpreadsheetComponent,
} from "@syncfusion/ej2-react-spreadsheet";
import { memo, RefObject } from "react";
import { ColumnHeaderStyle, ColumnStyle } from "../styles/SettingAndStyles";
import { spread } from "axios";
import { markObligatoryProperties } from "./spreadsheetUtil";

// save file custom function
export const saveExcel = (
  args: {
    fileName: string | Blob;
    saveType: string | Blob;
    url: RequestInfo | URL;
  },
  spreadsheetRef: any,
  isSave: boolean,
  loader: any
) => {
  let spreadsheet = spreadsheetRef?.current;
  spreadsheet?.saveAsJson().then((response: any) => {
    isSave = true;
    let formData = new FormData();
    formData.append("JSONData", JSON.stringify(response?.jsonObject?.Workbook));
    formData.append("fileName", args.fileName);
    formData.append("saveType", args.saveType);
    formData.append(
      "pdfLayoutSettings",
      JSON.stringify({ fitSheetOnOnePage: false, orientation: "Portrait" })
    );
    fetch(args.url, {
      method: "POST",
      body: formData,
    }).then((response) => {
      //To hide the custom spinner.
      classList(loader, ["e-spin-hide"], ["e-spin-show"]);
      if (response.ok) {
        response.blob().then((data) => {
          let anchor: any = createElement("a", {
            // @ts-ignore
            attrs: { download: args?.fileName },
          });
          const url = URL.createObjectURL(data);
          anchor.href = url;
          document.body.appendChild(anchor);
          anchor.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(anchor);
        });
      }
    });
  });
};

const uniqueArray = (arr: any[]) => {
  // Create a Set to keep track of unique combinations
  const seen = new Set();

  // Filter the array based on unique combinations
  return arr?.filter((item: { Title: any; key: any }) => {
    // Create a unique identifier for each combination
    const identifier = `${item.Title}-${item.key}`;

    // Check if the identifier is already in the Set
    if (seen.has(identifier)) {
      return false; // Duplicate found, exclude this item
    } else {
      seen.add(identifier); // Add the unique combination to the Set
      return true; // Keep this item
    }
  });
};

// reorder the sheet columns
//arrange model opens
export const reorderColumns = async (takeArguments: {
  spreadsheetRef: { current: any };
  columnHeaders: string[];
  arrangeColumnHeaders: any[];
  arrangeListbox: any;
  setHideShowSelectRows: any;
  tempDataSource: any;
  arrangeModalDialog: any;
  selectedEvent: any;
}) => {
  let {
    spreadsheetRef,
    columnHeaders,
    arrangeColumnHeaders,
    arrangeListbox,
    setHideShowSelectRows,
    tempDataSource,
    arrangeModalDialog,
    selectedEvent,
  } = takeArguments;
  let spreadsheet = spreadsheetRef.current;
  columnHeaders = [];
  // new logic for the column headers for new created columns
  if (selectedEvent == "spreadsheet_cmenu_delete_column") {
    arrangeColumnHeaders = [];
    arrangeListbox.dataSource = [];
  }
  let sheetIndex: any = spreadsheet?.activeSheetIndex;
  let usedColIdx = spreadsheet?.sheets[sheetIndex]?.usedRange?.colIndex!;
  let sheetName = spreadsheet?.sheets[sheetIndex]?.name;
  let colHeaderText = getColumnHeaderText(usedColIdx + 1);
  if (
    spreadsheet &&
    spreadsheet.sheets &&
    spreadsheet.sheets[sheetIndex] &&
    spreadsheet.sheets[sheetIndex].name &&
    colHeaderText
  ) {
    try {
      const range =
        spreadsheet?.sheets[sheetIndex]?.name + "!A1:" + colHeaderText + "1";
      await spreadsheet
        ?.getData(range)
        .then(
          (data: {
            forEach: (arg0: (value: CellModel, colKey: string) => void) => void;
          }) => {
            data?.forEach((value: CellModel, colKey: string) => {
              const key = colKey
                .split("")
                .filter((char: any) => isNaN(char))
                .join("");

              if (value.value == undefined) return;
              columnHeaders.push(value.value);
              if (
                window.ReorderColumnHeaders?.find(
                  (x: { Title: string | undefined; SheetName: any }) =>
                    x.Title === value.value && x.SheetName === sheetName
                ) === undefined
              ) {
                if (columnHeaders.indexOf(value.value) !== -1) {
                  const index = window.ReorderColumnHeaders?.findIndex(
                    (header: { key: string }) =>
                      header.key === key.replace(/[0-9]/g, "")
                  );
                  const updatedArray = [...arrangeListbox?.dataSource];
                  if (index !== -1 && index !== undefined) {
                    updatedArray[index]!.Title = value.value;
                    arrangeListbox.dataSource = updatedArray;
                  } else {
                    if (
                      window.ReorderColumnHeaders?.find(
                        (x) =>
                          x.Title === value.value && x.SheetName === sheetName
                      ) === undefined
                    ) {
                      window.ReorderColumnHeaders?.push({
                        Title: value.value,
                        IsAdmin: false,
                        Visible: true,
                        Hidden: false,
                        SheetName: sheetName,
                        Key: key.replace(/[0-9]/g, ""),
                        PropertyLabel: "-",
                      });
                      let rowIndex = arrangeListbox.dataSource?.findIndex(
                        (x) => x.Title === value.value
                      );
                      if (arrangeListbox?.dataSource[rowIndex] !== undefined) {
                        arrangeListbox.dataSource[rowIndex].Key = key.replace(
                          /[0-9]/g,
                          ""
                        );
                      }
                    }
                    // if (selectedEvent == "spreadsheet_cmenu_hide_column") {
                    //   console.log('hhhh');
                    //   arrangeListbox.dataSource.push({
                    //     Title: value.value,
                    //     IsAdmin: false,
                    //     Visible: true,
                    //     SheetName: sheetName,
                    //     key: key,
                    //     PropertyLabel: '-'
                    //   });
                    // }
                  }
                } else {
                  if (
                    window.ReorderColumnHeaders?.find(
                      (x) =>
                        x.Title === value.value && x.SheetName === sheetName
                    ) === undefined
                  ) {
                    window.ReorderColumnHeaders?.push({
                      Title: value.value,
                      IsAdmin: false,
                      Visible: false,
                      Hidden: false,
                      SheetName: sheetName,
                      Key: key.replace(/[0-9]/g, ""),
                      PropertyLabel: "-",
                    });
                  }
                }
              } else {
                if (window.isAdminColumnsAddedForTrans === true) {
                  window.RequiredAdminColumns?.forEach((col, index) => {
                    window.ReorderColumnHeaders.map((itemsData: any) => {
                      if (itemsData.Title === col) {
                        itemsData.Visible = true;
                      }
                      return itemsData;
                    });
                  });
                }
                window.isAdminColumnsAddedForTrans = false;
                let header = window.ReorderColumnHeaders?.find(
                  (x) => x.Title === value.value && x.SheetName === sheetName
                );
                let rowIndex = arrangeListbox.dataSource?.findIndex(
                  (x) => x.Title === value.value
                );
                if (header !== undefined) {
                  header.Key = key.replace(/[0-9]/g, "");
                }
                if (arrangeListbox?.dataSource[rowIndex] !== undefined) {
                  arrangeListbox.dataSource[rowIndex].Key = key.replace(
                    /[0-9]/g,
                    ""
                  );
                }
                //console.log(arrangeListbox?.dataSource);
              }
            });
          }
        );
      // Handle the data as needed
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  } else {
    console.error("Invalid spreadsheet, sheetIndex, or colHeaderText");
  }

  window.ReorderColumnHeaders?.filter(
    (x: { SheetName: any }) => x.SheetName === sheetName
  ).forEach((element) => {
    let required = window.ExistingPropIds?.find(
      (x) => x.Name === element.Title
    )?.Required;
    let automaticValueType = window.ExistingPropIds?.find(
      (x) => x.Name === element.Title
    )?.AutomaticValueType;
    if (element.IsAdmin == true) {
      element.PropertyLabel = "R";
    }
    if (required === true) {
      element.PropertyLabel = "O";
    }
    if (automaticValueType !== 0 && automaticValueType !== undefined) {
      element.PropertyLabel = "R";
    }
  });
  if (window.IsMFilesConnected === true) {
    if (arrangeListbox.dataSource.length == 0) {
      arrangeListbox.dataSource = uniqueArray(
        window.ReorderColumnHeaders?.filter(
          (x: { SheetName: any }) => x.SheetName === sheetName
        )
      );
    } else {
      console.log(
        "columnHeaders",
        window.ReorderColumnHeaders,
        arrangeListbox.dataSource,
        columnHeaders
      );
      // window.ReorderColumnHeaders.forEach(item => {
      //   if (!objectExists(arrangeListbox.dataSource, item)) {
      //       const index = findIndexByKey(arrangeListbox.dataSource, item.key);
      //       if (index !== -1) {
      //           // If the object with the same key exists, update it
      //           arrangeListbox.dataSource[index] = item;
      //       } else {
      //           // If the object does not exist, add it
      //           arrangeListbox.dataSource.push(item);
      //       }
      //   }
      // });
    }
  } else {
    if (arrangeListbox.dataSource.length == 0) {
      arrangeListbox.dataSource = uniqueArray(
        window.ReorderColumnHeaders?.filter(
          (x: { SheetName: any }) => x.SheetName === sheetName
        )
      );
    } else {
      // Process window.ReorderColumnHeaders to either add or update objects in arrangeListbox.dataSource
      window.ReorderColumnHeaders.forEach((item) => {
        if (!objectExists(arrangeListbox.dataSource, item)) {
          const index = findIndexByKey(arrangeListbox.dataSource, item.key);
          if (index !== -1) {
            // If the object with the same key exists, update it
            arrangeListbox.dataSource[index] = item;
          } else {
            // If the object does not exist, add it
            arrangeListbox.dataSource.push(item);
          }
        }
      });
    }
  }
  // The existing logic for the get column header
  arrangeListbox.dataSource = uniqueArray(arrangeListbox?.dataSource);

  setTimeout(() => {
    // if (arrangeListbox.dataSource?.length == 0) {
    //   arrangeListbox.dataSource = arrangeColumnHeaders.filter(
    //     (x: { SheetName: any }) => x.SheetName === currentSheetName
    //   );
    // }

    if (window.ReorderColumnHeaders?.length === 0) {
      setHideShowSelectRows(true);
    } else {
      setHideShowSelectRows(false);
    }
    //save the datasource of the listbox when opened
    (arrangeListbox.dataSource as object[])?.forEach(
      (listItems: any, index) => {
        tempDataSource.push({
          Title: listItems["Title"],
          IsAdmin: listItems["IsAdmin"],
          Hidden: listItems["Hidden"],
          Visible: listItems["Visible"],
          SheetName: listItems["SheetName"],
          Key: listItems["Key"],
          PropertyLabel: listItems["PropertyLabel"],
        });
      }
    );

    // (arrangeListbox?.dataSource as object[])?.forEach(
    //   (listItems: any, index) => {
    //     if (window.ColumnHeaders?.indexOf(listItems["Title"]) !== -1) {
    //         let createKey = getColumnHeader(index);
    //         if (createKey) {
    //           listItems["Key"] = createKey;
    //         }
    //   }
    //     return listItems;
    //   }
    // );

    arrangeListbox.fields = { text: "Title", value: "Title" };
    arrangeListbox.columns = [
      {
        headerText: "Visibility",
        field: "Visible",
        template:
          '<div class="template_checkbox">${ if(Visible) }<input type = "checkbox" class="visibility-checkbox" checked name="${Title}"> ${ else}<input type = "checkbox" class="visibility-checkbox" name="${Title}"> ${/if}</div>',
      },
      {
        headerText: "Column",
        field: "Key",
        isPrimaryKey: true,
      },
      {
        headerText: "Header",
        field: "Title",
        isPrimaryKey: true,
      },
      {
        headerText: "Label",
        field: "PropertyLabel",
        isPrimaryKey: true,
      },
    ];
    arrangeModalDialog?.show();
    selectedEvent = "";
  });
};

// get header text by index
const getColumnHeader = (index) => {
  let header = "";
  let tempIndex = index;
  while (tempIndex >= 0) {
    header = String.fromCharCode((tempIndex % 26) + 65) + header;
    tempIndex = Math.floor(tempIndex / 26) - 1;
  }

  return header;
};

// Function to find the index of an object in the array by key
const findIndexByKey = (arr, key) => {
  return arr.findIndex((item) => item.key === key);
};

// Function to check if an object exists in the array by comparing all properties
const objectExists = (arr, obj) => {
  return arr.some((item) => {
    return Object.keys(obj).every((key) => obj[key] === item[key]);
  });
};

// column hide show setting apply
//click Apply button in the arrange modal
export const rearrangeColumns = async(takeArgumentData: {
  arrangeListbox: any;
  alertDialog: any;
  currentColumnOrder: any[];
  spreadsheetRef: RefObject<SpreadsheetComponent>;
  arrangeModalDialog: any;
  tempDataSource: any;
  isSelectAllEvent: boolean;
  columnHeaders: any[];
  setHideUnHideAdmins: any;
  selectUnSelectAdminButton: any;
  setSelectedAdmin: any;
  tempSelectedAdminText: any;
}) => {
  let {
    arrangeListbox,
    alertDialog,
    currentColumnOrder,
    spreadsheetRef,
    arrangeModalDialog,
    tempDataSource,
    isSelectAllEvent,
    columnHeaders,
    setHideUnHideAdmins,
    selectUnSelectAdminButton,
    setSelectedAdmin,
    tempSelectedAdminText,
  } = takeArgumentData;
  let jsonData: any;
  setHideUnHideAdmins(true);
  if (selectUnSelectAdminButton === "Select Admin") {
    setSelectedAdmin(true);
    tempSelectedAdminText = "Select Admin";
  } else {
    tempSelectedAdminText = "Unselect Admin";
  }
  let visibilityCount = 0;
  (arrangeListbox.dataSource as object[]).forEach(
    (arrangeItems: any, index) => {
      if (arrangeItems["Visible"] == true) visibilityCount++;
    }
  );
  if (visibilityCount < 1) {
    alertDialog.show();
    return;
  }
  currentColumnOrder = [];
  let spreadsheet = spreadsheetRef.current;
  let sheetIndex = spreadsheet!.activeSheetIndex;
  let sheetName = spreadsheet?.getActiveSheet().name;

  arrangeModalDialog.hide();
  spreadsheet?.showSpinner();
  (arrangeListbox.dataSource as object[]).forEach(async (sdata, index) => {
    if (sdata["Visible"] === true || sdata["Hidden"] === true) {
        currentColumnOrder.push(sdata["Title"]);
     }
  });

  spreadsheet?.saveAsJson().then(async (response) => {
    jsonData = response;
    let obj = JSON.parse(JSON.stringify(jsonData.jsonObject.Workbook));
    let rows = obj.sheets[sheetIndex!].rows;
    let cells = obj.sheets[sheetIndex!].rows[0].cells;
    let temp: any[] = [];
    var obj1;
    for (let i = 1; i < rows.length - 1; i++) {
      obj1 = {};
      for (let j = 0; j < cells.length; j++) {
        var colHeader = rows[0].cells[j].value;

        if (colHeader === undefined) continue;
        obj1[colHeader] = rows[i]?.cells[j]?.value;
      }
      temp.push(obj1);
    }
    let memoryData = window.SheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    let rowIndex = spreadsheet?.getActiveSheet().usedRange?.rowIndex!;
    let colIndex = spreadsheet?.getActiveSheet().usedRange?.colIndex!;
    let colHeaderText = getColumnHeaderText(colIndex + 1);
    let tmprowIndex = rowIndex + 1;
    spreadsheet?.clear({
      type: "Clear All",
      range: "A1" + ":" + colHeaderText + tmprowIndex,
    });
    if (memoryData == undefined) {
      await updateCellValue(spreadsheetRef, temp, currentColumnOrder, arrangeListbox);
    } else
      await updateCellValue(
        spreadsheetRef,
        memoryData,
        currentColumnOrder,
        arrangeListbox
      );
  });

  isSelectAllEvent = false;
  columnHeaders = currentColumnOrder;
  
  spreadsheet?.hideSpinner();
  //spreadsheet?.selectRange(getCellAddress(0, 0));
  // arrangeColumnHeaders = [];
};

//arrange columns in the order in the spreadsheet
async function updateCellValue(
  spreadsheetRef: any,
  data: any,
  field: any,
  arrangeListbox?: any  
) {
  let spreadsheet = spreadsheetRef.current;
  let sheetIdx = spreadsheet!.activeSheetIndex;

  // Set the column header using specified field.
  field.forEach(function (field, i) {
    // updateCell method is used to update a cell properties.
    // let item = colStyleWidth && colStyleWidth[0];
    spreadsheet!.updateCell(
      {
        value: field,
        style: ColumnHeaderStyle,
      },
      getSheetName(spreadsheet!, sheetIdx) + "!" + getCellAddress(0, i)
    );
    //spreadsheet?.setColWidth(item && item[field], i, sheetIdx!);
  });
  // Loop the data source and get and set the proper cell value based on specified field mapping.
  data.forEach(function (item, i) {
    for (let j = 0; j < field.length; j++) {
      // updateCell method is used to update a cell properties.
      let propDataType = window.ExistingPropIds.find(
        (t) => t.Name === field[j]
      )?.DataType;
      //let item1 = memoryStyleData && memoryStyleData[i + 1];
      if (propDataType === 5) {
        var date = item[field[j]];
        spreadsheet!.updateCell(
          {
            value: date,
            format: "yyyy-mm-dd",
            style: ColumnStyle,
          },
          getSheetName(spreadsheet!, sheetIdx) + "!" + getCellAddress(i + 1, j)
        );
      } else {
        spreadsheet!.updateCell(
          {
            value: item[field[j]],
            style: ColumnStyle,
          },
          getSheetName(spreadsheet!, sheetIdx) + "!" + getCellAddress(i + 1, j)
        );
      }
    }
  });
  markObligatoryProperties(false, spreadsheetRef.current);
  let ind = 0;
  //Hide columns which are visible
  (arrangeListbox?.dataSource as object[]).forEach(
    async (arrangeItems: any, index: any) => {
      
       if (arrangeItems["Visible"] === true){       
        //setTimeout(async () => {
          ind++;
          await showColumnByHeader(arrangeItems?.Title, spreadsheetRef, ind, field);

          //currentColumnOrder.push(arrangeItems["Title"]);
        //});
      } else {
        setTimeout(async () => {
          const columnIndex = getColumnIndexByHeader(
            arrangeItems?.Title,
            spreadsheetRef
          );
          if (columnIndex !== -1){
            //its already visible..we are going to hide it now
            ind++;
            //currentColumnOrder.push(arrangeItems["Title"]);
          }
          if (arrangeItems["Hidden"] === true){
            await hideColumnByHeader(arrangeItems?.Title, spreadsheetRef, field);
          }
        });
      }
    }
  );
  spreadsheet?.selectRange(getCellAddress(0, 0));
  window.ColumnHeaders = field;
}

const hideColumnByHeader = (
  headerName: string | undefined,
  spreadsheetRef: RefObject<SpreadsheetComponent>, field: any
) => {
  //const columnIndex = getColumnIndexByHeader(headerName, spreadsheetRef);
  const columnIndex = field.indexOf(headerName);
  if (columnIndex !== -1) {
    // Hide the column by setting the width to 0
    const spreadsheet = spreadsheetRef?.current;
    spreadsheet?.hideColumn(columnIndex, columnIndex, true);
   // window.ColumnHeaders?.push(headerName)
  } else {
    // console.log("Column not found", headerName);
  }
};

const showColumnByHeader = async (
  headerName: string | undefined,
  spreadsheetRef: RefObject<SpreadsheetComponent>,
  index: any, field: any
) => {
  //const columnIndex = getColumnIndexByHeader(headerName, spreadsheetRef);
  const columnIndex = field.indexOf(headerName);
  if (columnIndex !== -1) {
    // Show the column by setting the width back to its original value
    const spreadsheet = spreadsheetRef?.current;
    spreadsheet?.hideColumn(columnIndex, columnIndex, false); //show column
   
    //remove the column from hidden columns
    //var ind = window.HiddenColumns?.indexOf(headerName);
    //if (ind > -1) {
     // window.HiddenColumns?.splice(ind, 1);
      //spreadsheet?.hideColumn(columnIndex, columnIndex, false); //show column
    //}
  } else {
    //console.log('show from M-Files');
   // showColumnByProperties(headerName, spreadsheetRef, index - 1);
  }
};

const showColumnByProperties = (
  headerName: string | undefined,
  spreadsheetRef: RefObject<SpreadsheetComponent>,
  index: any
) => {
  try {
    let currentSheetName = spreadsheetRef?.current?.getActiveSheet().name;
    //if the column is not present, insert the column
    const usedRange = spreadsheetRef?.current?.getActiveSheet().usedRange;
    //const usedColIdx = usedRange?.colIndex || 0;
   // window.ColumnHeaders?.push(headerName);
   //window.ColumnHeaders?.splice(index, 0, headerName);
    spreadsheetRef?.current?.insertColumn(index, index, currentSheetName);
    //adding header
    spreadsheetRef.current?.updateCell(
      { value: headerName, style: ColumnHeaderStyle },
      getCellAddress(0, index)
    );
    //adding values
    let memoryData = window.SheetMemoryData?.find(
      (x) => x.SheetName === currentSheetName
    )?.MemData;
    memoryData?.forEach(function (item, i) {
      spreadsheetRef.current?.updateCell({ value: item[headerName!] }, getCellAddress(i + 1, index));
    });
  } catch (e) {}
};

const getColumnIndexByHeader = (
  headerName: string | undefined,
  spreadsheetRef: RefObject<SpreadsheetComponent>
) => {
  const spreadsheet = spreadsheetRef?.current;
  const sheet: any = spreadsheet?.sheets[spreadsheet?.activeSheetIndex];

  // Find the column index by header name
  const headerRow = sheet && sheet?.rows[0];
  //console.log(headerRow, "headerRow")
  return headerRow?.cells?.findIndex(
    (cell: { value: string | undefined }) => cell?.value === headerName
  );
};

// cancel event for the arrange modal
export const cancelRearrangeColumns = (cancelArguments: {
  isSelectAllEvent: any;
  selectUnSelectButton: string;
  setSelectedAllRows: any;
  arrangeModalDialog: any;
  tempDataSource: any[];
  arrangeListbox: any;
  selectUnSelectAdminButton: any;
  setSelectedAdmin: any;
  setHideUnHideAdmins: any;
  isSelectAdminEvent: any;
}) => {
  let {
    isSelectAllEvent,
    selectUnSelectButton,
    setSelectedAllRows,
    arrangeModalDialog,
    tempDataSource,
    arrangeListbox,
    selectUnSelectAdminButton,
    setSelectedAdmin,
    setHideUnHideAdmins,
    isSelectAdminEvent,
  } = cancelArguments;
  if (isSelectAllEvent === true) {
    if (selectUnSelectButton === "Unselect All") {
      setSelectedAllRows(true);
      if (selectUnSelectAdminButton === "Unselect Admin") {
        setSelectedAdmin(true);
      }
    }
    if (selectUnSelectButton === "Select All") {
      setSelectedAllRows(false);
    }
  }

  setHideUnHideAdmins(true);
  if (isSelectAdminEvent === true) {
    if (selectUnSelectAdminButton === "Select Admin") {
      setSelectedAdmin(false);
    }
    if (selectUnSelectAdminButton === "Unselect Admin") {
      setSelectedAdmin(true);
    }
    if (
      isSelectAllEvent === true &&
      selectUnSelectAdminButton === "Select Admin"
    ) {
      setSelectedAdmin(true);
    }
  }

  arrangeModalDialog.hide();
  //restore datasource of the listbox when closed
  (tempDataSource as object[]).forEach((items: any, index) => {
    (arrangeListbox.dataSource as object[]).forEach((newItems: any, index1) => {
      if (items["Title"] === newItems["Title"]) {
        newItems["Visible"] = items["Visible"];
      }
    });
  });

  arrangeListbox.refresh();
  isSelectAllEvent = false;
  isSelectAdminEvent = false;
};

// default function of the spreadsheet component
export const onContextMenuItemSelect = (
  args: MenuSelectEventArgs,
  contextMenuArguments: {
    spreadsheetRef: RefObject<SpreadsheetComponent>;
    clickedColumnName: null;
    arrangeColumnHeaders: any[];
    arrangeListbox: any;
    setClickedColumnName: any;
  }
) => {
  let {
    spreadsheetRef,
    clickedColumnName,
    arrangeColumnHeaders,
    arrangeListbox,
    setClickedColumnName,
  } = contextMenuArguments;
  if (
    args.item.id ===
    spreadsheetRef?.current?.element?.id + "_cmenu_delete_column"
  ) {
    multipleColumnDelete(
      spreadsheetRef,
      // arrangeColumnHeaders,
      window.ReorderColumnHeaders,
      clickedColumnName,
      setClickedColumnName,
      arrangeListbox
    );
  }

  if (
    args.item.id ===
    spreadsheetRef?.current?.element?.id + "_cmenu_hide_column"
  ) {
    hideUnHideMultipleColumn(
      spreadsheetRef,
      window.ReorderColumnHeaders,
      "hideColumn",
      clickedColumnName,
      setClickedColumnName,
      arrangeListbox
    );
  } else if (
    args.item.id ===
    spreadsheetRef?.current?.element?.id + "_cmenu_unhide_column"
  ) {
    hideUnHideMultipleColumn(
      spreadsheetRef,
      // arrangeColumnHeaders,
      window.ReorderColumnHeaders,
      "unHideColumn",
      clickedColumnName,
      setClickedColumnName,
      arrangeListbox
    );
  }
};

export const hideUnHideMultipleColumn = (
  spreadsheetRef: RefObject<SpreadsheetComponent>,
  arrangeColumnHeaders: any[],
  eventType: string,
  clickedColumnName: any,
  setClickedColumnName: any,
  arrangeListbox: any
) => {
  const spreadsheet = spreadsheetRef?.current;
  if (spreadsheet) {
    const sheet: any = spreadsheet.getActiveSheet();
    const selectedRange = sheet?.selectedRange;

    if (selectedRange) {
      const [startCell, endCell] = selectedRange.split(":");
      const startCellCoords: any = startCell.match(/([A-Z]+)(\d+)/);
      const endCellCoords: any = endCell.match(/([A-Z]+)(\d+)/);

      if (startCellCoords && endCellCoords) {
        const startColumnIndex = getColumnIndex(startCellCoords[1]);
        const endColumnIndex = getColumnIndex(endCellCoords[1]);

        if (startColumnIndex !== endColumnIndex) {
          const firstRow = sheet && sheet?.rows[0].cells;
          const columnNames: any[] = [];

          for (
            let colIndex = startColumnIndex;
            colIndex <= endColumnIndex;
            colIndex++
          ) {
            if (firstRow[colIndex]) {
              columnNames.push(firstRow[colIndex].value);
            }
          }
          if (columnNames) {
            arrangeColumnHeaders.map((item) => {
              const isTitleInSecondArray = columnNames?.some(
                (val) => val === item.Title
              );
              if (isTitleInSecondArray) {
                item.Visible = eventType === "hideColumn" ? false : true;
              }
              return item;
            });

            arrangeListbox.dataSource.map(
              (item: { Title: any; Visible: boolean }) => {
                const isTitleInSecondArray = columnNames?.some(
                  (val) => val === item.Title
                );
                if (isTitleInSecondArray) {
                  item.Visible = eventType === "hideColumn" ? false : true;
                }
                return item;
              }
            );
          }
        } else {
          if (clickedColumnName) {
            arrangeColumnHeaders.map(
              (items: { Title: any; Visible: boolean }) => {
                if (items.Title === clickedColumnName) {
                  items.Visible = false;
                }
                return items;
              }
            );
            arrangeListbox.dataSource.map(
              (items: { Title: any; Visible: boolean }) => {
                if (items.Title === clickedColumnName) {
                  items.Visible = false;
                }
                return items;
              }
            );
          }
          setTimeout(() => {
            setClickedColumnName(null);
          }, 100);
        }
      }
    }
  }
};

export const multipleColumnDelete = (
  spreadsheetRef: RefObject<SpreadsheetComponent>,
  arrangeColumnHeaders: any[],
  clickedColumnName: any,
  setClickedColumnName: any,
  arrangeListbox: any
) => {
  const spreadsheet = spreadsheetRef?.current;
  if (spreadsheet) {
    const sheet: any = spreadsheet.getActiveSheet();
    const selectedRange = sheet?.selectedRange;

    if (selectedRange) {
      const [startCell, endCell] = selectedRange.split(":");
      const startCellCoords: any = startCell.match(/([A-Z]+)(\d+)/);
      const endCellCoords: any = endCell.match(/([A-Z]+)(\d+)/);
      if (startCellCoords && endCellCoords) {
        const startColumnIndex = getColumnIndex(startCellCoords[1]);
        const endColumnIndex = getColumnIndex(endCellCoords[1]);

        if (startColumnIndex !== endColumnIndex) {
          const firstRow = sheet && sheet?.rows[0].cells;
          const columnNames: any[] = [];
          for (
            let colIndex = startColumnIndex;
            colIndex <= endColumnIndex;
            colIndex++
          ) {
            if (firstRow[colIndex]) {
              columnNames.push(firstRow[colIndex].value);
            }
          }
          if (columnNames) {
            arrangeColumnHeaders = arrangeColumnHeaders?.filter(
              (item) => !columnNames?.includes(item.Title)
            );
            arrangeListbox.dataSource = arrangeListbox.dataSource?.filter(
              (item: { Title: any }) => !columnNames?.includes(item.Title)
            );
          }
        } else {
          if (clickedColumnName) {
            arrangeColumnHeaders = arrangeColumnHeaders
              ?.map((item: { Title: any }) =>
                item.Title !== clickedColumnName ? item : null
              )
              .filter((item: any) => item !== null);

            arrangeListbox.dataSource = arrangeListbox?.dataSource
              ?.map((item: { Title: any }) =>
                item.Title !== clickedColumnName ? item : null
              )
              .filter((item: null) => item !== null);
          }
          setTimeout(() => {
            setClickedColumnName(null);
          }, 100);
        }
      }
    }
  }
};

// Helper function to convert column letter to index
const getColumnIndex = (column: string) => {
  let index = 0;
  for (let i = 0; i < column.length; i++) {
    index = index * 26 + column.charCodeAt(i) - "A".charCodeAt(0) + 1;
  }
  return index - 1; // zero-based index
};

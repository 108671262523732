import { CellModel, getCell, getColumnHeaderText } from "@syncfusion/ej2-react-spreadsheet";
import { GetProperties } from "./MFilesDto";
import { GetOwnerId } from "./MFilesService";
import { currentRow } from "../Spreadsheet";
import { markObligatoryProperties } from "../../utils/spreadsheetUtil";


export async function getObjectPropertiesBL(spreadsheet, sheetIndex) {
    await getColumnHeaders(sheetIndex, spreadsheet);// fills columnHeaders 
    let clsId = getCell(
      1,//first row
      window.ColumnHeaders?.indexOf("Object Class"),
      spreadsheet!.getActiveSheet()
    )?.value;
    let sheetName = spreadsheet!.getActiveSheet().name!;
    await GetProperties(sheetName, clsId);  //fills existingPropIds, singleselectProperties, multiselectProperties, lookupIds
    console.log(window.ExistingPropIds);
    if (clsId === undefined) return;
   // await IsLookupIdDefined(clsId);
    if (clsId === "-1") return;   
    
    //mark required, automatic columns with different colors
   markObligatoryProperties(false, spreadsheet);
  
  
    //filter data array by owner ID and get the owner ID
    window.ExistingPropIds?.forEach((prop) => {
      let propId = prop.Id;
      let propDataType = prop.DataType;
      let ownerId = -1;

      if (propDataType === 9 || propDataType === 10) {
        if (prop.DepPD > 0) {
          let ownerPD = window.ExistingPropIds?.find((x) => x.Id == prop.DepPD)?.Name;
          let ownerPDCol = window.ColumnHeaders?.indexOf(ownerPD);
          if (ownerPDCol !== -1) {
            let ownerPDValue = getCell(
              currentRow,
              ownerPDCol,
              spreadsheet!.getActiveSheet()
            )?.value;
            ownerId = GetOwnerId(prop.DepPD, ownerPDValue);
          }
          if (ownerId != -1) {
            if (propDataType === 9 && window.SingleSelectProperties?.find((x) => x.Id == propId) !== undefined) {
              let items =  window.SingleSelectProperties?.find((x) => x.Id == propId)?.DataArray;              
               window.SingleSelectProperties.find((x) => x.Id == propId).DataArray = items?.filter((x) => x.OwnerID == ownerId);
              }
              if (propDataType === 10 && window.MultiSelectProperties?.find((x) => x.Id == propId) !== undefined) {
                let items = window.MultiSelectProperties?.find((x) => x.Id == propId).DataArray;              
                window.MultiSelectProperties.find((x) => x.Id == propId).DataArray = items.filter((x) => x.OwnerID == ownerId);
                }
          }
        }
      }
    });
  }

  export async function getColumnHeaders(sheetIndex, spreadsheet) {
    window.ColumnHeaders = [];
   // window.ReorderColumnHeaders = [];
    let usedColIdx = spreadsheet?.sheets[sheetIndex].usedRange?.colIndex!;
    let sheetName = spreadsheet?.sheets[sheetIndex].name;
    let colHeaderText = getColumnHeaderText(usedColIdx + 1);
    let propLabel = '-';
    await spreadsheet
      ?.getData(
        spreadsheet?.sheets[sheetIndex].name + "!A1:" + colHeaderText + "1"
      )
      .then((data) => {
        //console.log(data);
        data?.forEach((value: CellModel, key: string) => {
          if (value.value == undefined) return;
          window.ColumnHeaders?.push(value.value);
          //console.log(value.value);
          if (window.ReorderColumnHeaders?.find((x) => x.Title === value.value && x.SheetName === sheetName) === undefined) {
            if (window.AdminColumns?.find((x) => x.Title == value.value) !== undefined) {//present in Admin Columns
                window.ReorderColumnHeaders?.push({
                Title: value.value,
                IsAdmin: true,
                Visible: false,
                Hidden: false,
                SheetName: sheetName,
                PropertyLabel: propLabel,
                Key: key.replace(/[0-9]/g, '')
                });
            }
            else{
                if (window.ColumnHeaders?.indexOf(value.value) !== -1) {
                    window.ReorderColumnHeaders?.push({
                        Title: value.value,
                        IsAdmin: false,
                        Visible: true,
                        Hidden: false,  
                        SheetName: sheetName,
                        PropertyLabel: propLabel,
                        Key: key.replace(/[0-9]/g, '')
                    });
                    } else {
                    window.ReorderColumnHeaders?.push({
                        Title: value.value,
                        IsAdmin: false,
                        Visible: false,
                        Hidden: false,
                        SheetName: sheetName,
                        PropertyLabel: propLabel,
                        Key: key.replace(/[0-9]/g, '')
                    });
                    }

                }
        }
        });
      });
  }

export function createProperty(propId: number, propName: string, propDataType: number, cellValue: any) {
  if (cellValue == null) return null;

  const baseProperty = {
      PropertyDef: propId,
      TypedValue: {
          DataType: propDataType,
          Value: cellValue
      }
  };

  switch (propDataType) {
      case 5:
      case 7:
          if (propName === "Last Modified") {
              baseProperty.TypedValue.Value = new Date().toISOString();
          }
          break;
      case 9:
          return createSingleSelectProperty(propId, cellValue);
      case 10:
          return createMultiSelectProperty(propId, cellValue);
      default:
          return baseProperty;
  }
}

function createSingleSelectProperty(propId: number, cellValue: any) {
    const items =  window.SingleSelectProperties?.find(x => x.Id === propId)?.DataArray;
    const clsId =  window.SingleSelectProperties?.find(x => x.Id === propId)?.ClsId;
    const lkupId = window.LookupIds?.find(x => x.clsId === clsId)?.lkupId;

    if (!items) return null;

    let item;
    if (clsId === -1) {
        item = items.find(x => x.Name === cellValue)?.ID;
    } else if (lkupId !== undefined) {
        item = items.find(x => x.LookupId === cellValue)?.ObjVer.ID;
    } else {
        item = items.find(x => x.Title === cellValue)?.ObjVer.ID;
    }

    return item !== undefined ? {
        PropertyDef: propId,
        TypedValue: {
            DataType: 9,
            Lookup: { Item: item }
        }
    } : null;
}

function createMultiSelectProperty(propId: number, cellValue: any) {
  const items = window.MultiSelectProperties?.find(x => x.Id === propId)?.DataArray;
  const clsId = window.MultiSelectProperties?.find(x => x.Id === propId)?.ClsId;
  const isMultipleClass = window.MultiSelectProperties?.find(x => x.Id === propId)?.IsMultipleClass;

  if (!items) return null;

  const tmpclsId = isMultipleClass && !Number.isInteger(clsId) ? clsId?.split(",")[0] : clsId;
  const lkupId = window.LookupIds?.find(x => x.clsId === Number(tmpclsId))?.lkupId;

  const lookupArray = cellValue.split(";").map(val => {
      const item = findItemForMultiSelect(items, clsId, lkupId, val.trim());
      return item ? { Item: item, Version: -1 } : null;
  }).filter(Boolean);

  return lookupArray.length ? {
      PropertyDef: propId,
      TypedValue: {
          DataType: 10,
          Lookups: lookupArray
      }
  } : null;
}

function findItemForMultiSelect(items: any[], clsId: number, lkupId: any, val: string) {
  if (clsId === -1) {
      return items.find(x => x.Name === val)?.ID;
  } else if (lkupId !== undefined) {
      return items.find(x => x.LookupId === val)?.ObjVer.ID;
  } else {
      return items.find(x => x.Title === val)?.ObjVer.ID;
  }
}
